import React from 'react';
import NavBar, { NavBarItem } from 'common/components/NavBar';
import useAdminState from 'admin/hooks/useAdminState';
import { ADMIN_ROUTES } from 'admin/constants';

const AdminNavbar = () => {
    const { state, actions, cognitoClient } = useAdminState();

    const handleSignOut = () => {
        cognitoClient.logout();
        actions.updateState({
            currentUser: null,
            isAuth: false,
            modalSignInActive: true,
            modalConfirmUserActive: false,
        });
    };

    return (
        <NavBar
            withWhiteBg={false}
            withProfileInfo={false}
            userName={state.currentUser?.firstName}
            isAuth={state.isAuth}
            onSignIn={() => actions.updateState({ modalSignInActive: true })}
            onSignOut={handleSignOut}
            isAdmin={true}
        >
        </NavBar>
    );
};

export default AdminNavbar;
