import React, { useState } from 'react';
import SignInModal from 'common/modals/ModalSignIn';
import useAdminState from 'admin/hooks/useAdminState';
import { ADMIN_ROUTES } from 'admin/constants';
import { useHistory } from 'react-router-dom';

const MarketplaceSignInModal = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { state, actions, apiClient, cognitoClient } = useAdminState();
    const history = useHistory();
    const [isLogError, setIsLogError] = useState(null);

    const handleLogin = (email, password) => {
        setIsLoading(true);
        cognitoClient
            .login(email, password)
            .then(async (data) => {
                if (data.isNewUser) {
                    history.push(ADMIN_ROUTES.HOME.concat(ADMIN_ROUTES.CONFIRM_USER));
                    actions.updateState({
                        modalSignInActive: false,
                        modalConfirmUserActive: true,
                    });
                    return;
                }

                const currentUser = await apiClient.getCurrentAdmin();
                actions.updateState({
                    currentUser,
                    isAuth: true,
                    modalSignInActive: false,
                    modalConfirmUserActive: false,
                });
            })
            .catch((err) => {
                console.error(err);
                setIsLogError('Incorrect username or password.');
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <SignInModal
            open={state.modalSignInActive}
            isLoading={isLoading}
            onClose={() => actions.updateState({ modalSignInActive: false })}
            onSubmit={handleLogin}
            isLogError={isLogError}
        />
    );
};

export default React.memo(MarketplaceSignInModal);
