import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import theme from '../themes/theme';
import AdminStateProvider from './providers/GlobalState';
import AdminRoutes from './App';
import { BrowserRouter } from 'react-router-dom';
import 'common/index.css';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={2} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <BrowserRouter>
                <AdminStateProvider>
                    <AdminRoutes />
                </AdminStateProvider>
            </BrowserRouter>
        </SnackbarProvider>
    </ThemeProvider>,
    document.getElementById('root'),
);
