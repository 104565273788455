import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import isEmpty from 'validator/es/lib/isEmpty';
import CircularProgress from '@material-ui/core/CircularProgress';
import closeBtn from 'assets/img/close-btn.svg';
import { useStyles } from 'marketplace/components/Modal/style';
import useAdminState from 'admin/hooks/useAdminState';
import { ADMIN_ROUTES } from 'admin/constants';
import Fade from '@material-ui/core/Fade';

const ConfirmUserModal = () => {
    const { state, actions, apiClient, cognitoClient } = useAdminState();
    const [newPassword, setNewPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const signInClasses = useStyles();
    const history = useHistory();
    const [isLogError, setIsLogError] = useState(null);

    useEffect(() => {
        if (!isEmpty(newPassword) && !isEmpty(firstName) && !isEmpty(lastName)) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [newPassword, firstName, lastName]);

    const onSubmit = () => {
        setIsLoading(true);
        cognitoClient
            .confirmRegistration({ newPassword, firstName, lastName })
            .then(async () => {
                const currentUser = await apiClient.getCurrentAdmin();
                history.push(ADMIN_ROUTES.HOME);
                actions.updateState({
                    currentUser,
                    modalSignInActive: false,
                    modalConfirmUserActive: false,
                    isAuth: true,
                });
            })
            .catch((err) => {
                setIsLogError('Your password is not strong enough.');
                if (err.name === 'NotAuthorizedException') {
                    setIsLogError('Your session has expired. Please refresh or log in again.');
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleClose = () => {
        history.push(ADMIN_ROUTES.HOME);
        actions.updateState({
            modalConfirmUserActive: false,
        });
    };

    return (
        <div>
            <Fade in={state.modalConfirmUserActive} timeout={200}>
                <div
                    className={clsx(signInClasses.modal, {[signInClasses.showModal]: state.modalConfirmUserActive,
                    })}
                    onClick={(e) => e.stopPropagation()} >

                    <div className={signInClasses.modalSignInWrapper} onClick={(e) => e.stopPropagation()}>
                        <div className={signInClasses.modalSignInCloseBtn} onClick={handleClose}>
                            <img className={signInClasses.modalSignInImg} src={closeBtn} alt="close" />
                        </div>
                        <div className={signInClasses.modalSignInInputBlock}>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    onSubmit();
                                }}
                                className={signInClasses.signInForm}
                                autoComplete="off"
                            >
                                <div className={signInClasses.modalSignInInputField}>
                                    <label className={signInClasses.modalSignInInputLabel}>
                                        First Name
                                        <input
                                            className={signInClasses.modalSignInInput}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            value={firstName}
                                            type="text"
                                            name="given_name"
                                            role="presentation"
                                            autoComplete="off"
                                            placeholder="Enter here"
                                        />
                                    </label>
                                </div>
                                <div className={signInClasses.wrapperForMsg}>
                                </div>
                                <div className={signInClasses.modalSignInInputField}>
                                    <label className={signInClasses.modalSignInInputLabel}>
                                        Last Name
                                        <input
                                            className={signInClasses.modalSignInInput}
                                            onChange={(e) => setLastName(e.target.value)}
                                            value={lastName}
                                            type="text"
                                            name="family_name"
                                            role="presentation"
                                            autoComplete="off"
                                            placeholder="Enter here"
                                        />
                                    </label>
                                </div>
                                <div className={signInClasses.wrapperForMsg}>
                                </div>
                                <div className={signInClasses.modalSignInInputField}>
                                    <label className={signInClasses.modalSignInInputLabel} htmlFor="new-password">
                                        New Password
                                        <input
                                            className={signInClasses.modalSignInInput}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            value={newPassword}
                                            type="password"
                                            name="new-password"
                                            id="new-password"
                                            role="presentation"
                                            autoComplete="new-password"
                                            placeholder="Enter here"
                                        />
                                    </label>
                                </div>
                                <div className={signInClasses.wrapperForMsg}>
                                    {/*{!isPasswordValid && <div className={classes.errMsg}>{errorPassMsg}</div>}*/}
                                    <div className={signInClasses.errMsg}>{isLogError}</div>
                                </div>
                                <button
                                    className={signInClasses.modalSignInCreatBtn}
                                    disabled={isLoading || isDisabled}
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={10} /> : 'Confirm'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    );
};

export default React.memo(ConfirmUserModal);
