import React, { memo, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import ScrollToTop from 'common/components/ScrollToTop';
import PrivatRouter from 'common/components/PrivateRouter';
import Footer from 'common/components/Footer';
import PageContainer from 'common/components/PageContainer';
import { ADMIN_ROUTES } from 'admin/constants';
import AdminPage from 'admin/pages/AdminPage/AdminPage';
import useAdminState from 'admin/hooks/useAdminState';
import AdminNavbar from 'admin/components/NavBar';
import SignInModal from 'admin/components/SignInModal';
import ConfirmUserModal from 'admin/components/ConfirmUserModal';
import EmptyPage from 'admin/pages/EmptyPage';

const AdminApp = () => {
    const { state, actions, cognitoClient, apiClient } = useAdminState();
    const history = useHistory();

    useEffect(() => {
        cognitoClient.restoreSession().then(async () => {
            const currentUser = await apiClient.getCurrentAdmin();
            history.push(ADMIN_ROUTES.HOME);
            actions.updateState({
                currentUser,
                isAuth: true,
                modalSignInActive: false,
                modalConfirmUserActive: false,
            });
        });
    }, []);

    useEffect(() => {
        const urlHash = window.location.hash;
        switch (urlHash) {
            case ADMIN_ROUTES.LOGIN:
                actions.updateState({
                    modalSignInActive: true,
                    modalConfirmUserActive: false,
                });
                break;

            case ADMIN_ROUTES.CONFIRM_USER:
                actions.updateState({
                    modalSignInActive: false,
                    modalConfirmUserActive: true,
                });
                break;

            default:
                actions.updateState({
                    modalSignInActive: false,
                    modalConfirmUserActive: false,
                });
                break;
        }
    }, [window.location.hash]);

    return (
        <>
            {state.modalSignInActive && <SignInModal />}
            {state.modalConfirmUserActive && <ConfirmUserModal />}
            <AdminNavbar />
            <ScrollToTop />
            <PageContainer>
                <Switch>
                    <Route path={ADMIN_ROUTES.HOME.concat(ADMIN_ROUTES.CONFIRM_USER)} exact>
                        <EmptyPage />
                    </Route>
                    <Route path={ADMIN_ROUTES.HOME.concat(ADMIN_ROUTES.LOGIN)} exact>
                        <EmptyPage />
                    </Route>

                    <PrivatRouter
                        path={ADMIN_ROUTES.HOME}
                        redirectPath={ADMIN_ROUTES.HOME.concat(ADMIN_ROUTES.LOGIN)}
                        exact
                        isAuth={!!state.currentUser}
                    >
                        <AdminPage />
                    </PrivatRouter>
                    <Route path="*">
                        <Redirect to={ADMIN_ROUTES.HOME} />
                    </Route>
                </Switch>
            </PageContainer>
            <Footer />
        </>
    );
};

export default memo(AdminApp);
