import React from 'react';

const EmptyPage = ({ children }) => {
    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
            }}
        >
            {children}
        </div>
    );
};

export default React.memo(EmptyPage);
